.thank-you-lenders__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: var(--white);
  height: 100vh;
}

.thank-you-lenders__title {
  font-size: 1em;
  font-weight: 700;
  color: var(--dove-gray);
  text-align: center;
  margin-top: 0.8em;
}

.thank-you-lenders__list {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  width: 95vw;
  height: 100%;
}

.thank-you-lenders__card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90vw;
}

.thank-you-lenders__card {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: var(--white);
  cursor: pointer;
  transition-property: box-shadow, transform;

  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);

  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.thank-you-lenders__img {
  text-align: center;
  margin-top: 2em;
}

.thank-you-lenders__img img {
  margin-bottom: 10px;
  width: 220px;
}

.thank-you-lenders__description {
  margin: 0.5em 3.5em 0.5em 3.5em;
  text-align: center;
}

.thank-you-lenders__description-header {
  width: 100%;
  line-height: 2.2em;
}

.thank-you-lenders__description-header span {
  display: inline-block;
  font-size: 1.3em;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--navy-blue);
}

.thank-you-lenders__description-bulletpoints {
  text-align: justify;
  font-family: inherit;
  font-size: 14px;
}

.thank-you-lenders__quote-container {
  font-size: 1em;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin: auto;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.thankyou__lenders-quote-btn {
  padding: 13px 32px;
  background-color: var(--navy-blue);
  font-size: 18px;
  color: var(--white);
  text-decoration: none;
  display: table;
  text-align: center;
  font-weight: 900;
  z-index: 2;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin-bottom: 2em;
}

.thank-you-lenders__phone {
  text-align: center;
  font-size: 1.2rem;
  color: var(--navy-blue);
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  line-height: 2em;
}

.thank-you-lenders__pixel {
  display: none;
}

@media screen and (min-width: 768px) {
  .thank-you-lenders__container {
    gap: 4rem;
    position: relative;
  }

  .thank-you-lenders__card-wrapper {
    gap: 2.5rem;
  }

  .thank-you-lenders__title {
    font-size: 1.7rem;
  }

  .thank-you-lenders__card {
    flex-direction: row;
    padding: 2em;
  }

  .thank-you-lenders__phone {
    font-size: 1.7rem;
  }

  .thank-you-lenders__img img {
    width: 200px;
  }

  .thank-you-lenders__description-header span {
    text-align: left;
  }

  .thank-you-lenders__description-bulletpoints {
    font-size: 18px;
  }
}
