.main-form__wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;
  margin-bottom: 2rem;
}

.main-form__title {
  text-align: center;
  color: var(--charcoal);
  text-shadow: none;
  font-weight: 600;
  font-size: 19px;
  margin: 0.3em 0 0.4em;
  line-height: 22px;
}

.main-form__subtitle {
  color: var(--navy-blue);
  font-size: 15px;
  line-height: 20px;
  text-shadow: none;
  margin: 0.1em 0;
}

.main-form__list {
  margin: 0;
  list-style-position: outside;
  font-size: 18px;
  color: var(--navy-blue);
}

.main-form__list li {
  margin-bottom: 1rem;
}

.main-form__img {
  height: 60px;
  margin-top: 5em;
}

.main-form {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}

.form-step__error-box {
  border-top: 3px solid var(--soft-red);
  color: var(--soft-red);
  background-color: var(--light-pink);
  text-align: center;
  padding: 0.7em;
}

.main-form__step-container {
  background-color: var(--white);
  padding: 20px;
  text-align: center;
  border-radius: 4px;
  position: relative;
}

.main-form__step-info {
  font-size: 15px;
  color: var(--navy-blue);
  font-weight: 600;
}

.main-form__img-container {
  display: flex;
  justify-content: center;
}

.main-form__hint {
  background: #f7f7f3 url(../../assets/img/light-bulb-icon.svg) 13px 13px
    no-repeat;
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  margin: 2em auto 0;
  max-width: 450px;
  padding: 15px 25px 15px 60px;
  position: relative;
  width: 100%;
  text-align: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.main-form__hint-title {
  font-weight: 700;
  margin-bottom: 0 0 2px;
}

@media (min-width: 768px) {
  .main-form__wrapper {
    margin-top: 1.5rem;
  }

  .main-form__title {
    font-size: 46px;
    line-height: 48px;
    color: var(--white);
    margin: 0.3em 0 0.4em;
  }

  .main-form__subtitle {
    font-size: 26px;
    line-height: 38px;
    font-weight: 100;
    color: var(--white);
    margin: 0;
  }

  .main-form__list {
    padding: 1em 0 0 1em;
  }

  .main-form__list li {
    color: #fff;
    margin: 0.5em 0;
    list-style-type: disc;
    padding: 0;
    list-style-position: outside;
  }

  .main-form {
    width: auto;
  }

  .form-step__error-box {
    width: 460px;
    padding: 20px 40px 30px 40px;
    font-size: 18px;
  }

  .main-form__step-container {
    width: 460px;
    padding: 20px 40px 30px 40px;
  }

  .main-form__step-info {
    text-align: left;
    font-size: 18px;
    line-height: 38px;
  }

  .main-form__img-container {
    justify-content: right;
  }
}
